/* eslint-disable no-console */
import Tracker from "@openreplay/tracker";

class OpenReplay {
  #api;

  #isStarted = false;

  constructor() {
    try {
      this.#api = new Tracker({
        projectKey: process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY,
      });
    } catch (error) {
      console.error({error, errorInfo: "[OpenReplay]: Error during OpenReplay initialization"});
    }
  }

  /**
   * Track user screen session using open replay.
   */
  runOpenReplay = async profile => {
    try {
      if (!this.#isStarted) {
        const {agency, user} = profile;

        this.#api.start({
          userID: user.email,
          metadata: {
            agentId: user.id,
            agencyId: agency.id,
            agencyName: agency.name,
          },
        });

        this.#isStarted = true;
      }
    } catch (error) {
      console.error({error, errorInfo: "[OpenReplay]: Failed to identify the user"});
    }
  };
}

export default new OpenReplay();
