import {createSlice} from "@reduxjs/toolkit";
import {FORM_ERROR} from "final-form";

import {REFERENCING_TYPES} from "constants/rentPassport";
import {
  getAgentBranchesForRequestFullReferencing,
  getAvailableBranches,
  getBranchConnections,
} from "http/branches";
import {
  requestFullScreening,
  requestFullScreeningBranchConnection,
  requestRentPassport,
  requestFullScreeningFromGuarantorNoConnection,
} from "http/connections";
import {linkToProperty, postProperty} from "http/properties";
import {logout} from "redux/auth/actions";
import {extractError} from "redux/helpers";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import analytics from "services/analytics";
import remoteConfig from "services/remote-config";
import {
  IS_IDENTITY_VERIFICATION_ENABLED,
  WHITELISTED_IDV_AGENCIES,
} from "services/remote-config/constants";

import initialState, * as handlers from "./handlers";
import {prepareValuesForSendRpRequest} from "./helpers";

const {actions, reducer} = createSlice({
  reducers: {
    availableBranchesRequest: startLoading,
    availableBranchesSuccess: handlers.getBranchesForRPInviteSuccess,
    availableBranchesFailure: loadingFailed,
    clearAvailableBranches: handlers.clearAvailableBranchesData,
    getIsIdentityVerificationEnableRequest: startLoading,
    getIsIdentityVerificationEnableSuccess: handlers.getIsIdentityVerificationEnableSuccess,
    getIsIdentityVerificationEnableFailure: loadingFailed,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "rentPassportRequest",
});

export const requestBranchesForRpInvite = () => async dispatch => {
  try {
    dispatch(actions.availableBranchesRequest());
    const branches = await getAvailableBranches();

    dispatch(actions.availableBranchesSuccess(branches));
  } catch (e) {
    dispatch(actions.availableBranchesFailure(extractError(e)));
  }
};

export const sendRPInvite = ({
  callback,
  isMovingSoon,
  isPropertiesAvailable,
  pathname,
  properties,
  ...values
}) => async dispatch => {
  try {
    const renterInvite = await requestRentPassport(
      prepareValuesForSendRpRequest(values, isPropertiesAvailable),
    );

    if (isPropertiesAvailable && properties) {
      let toLink = properties;
      const unsubmittedProperties = toLink.filter(p => p.id.startsWith("address-"));

      if (unsubmittedProperties.length > 0) {
        const requests = unsubmittedProperties.map(p => {
          const data = {...p};

          delete data.id;
          delete data.addressTitle;
          delete data.state;

          return postProperty(data);
        });

        const createdProperties = await Promise.all(requests);

        toLink = toLink.map(p => {
          const createdProperty = createdProperties.find(c => c.addressTitle === p.addressTitle);

          return createdProperty || p;
        });
      }

      const linkPropertyRequests = toLink.map(property =>
        linkToProperty(property.id, {renterInviteId: [renterInvite.id]}),
      );

      await Promise.all(linkPropertyRequests);
    }

    analytics.logEvent("agentRequestRPComplete", {pathname});

    if (isMovingSoon && values?.referencingType === REFERENCING_TYPES.PRIORITY) {
      analytics.logEvent("nestedEvent", {
        nestedEventName: "agentInvitePriorityRequestedAfterMovingSoonPrompt",
      });
    }

    if (callback) {
      callback(renterInvite);
    }

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.availableBranchesFailure(error));
    analytics.logEvent("agentRequestRPFailure", {pathname, error});

    return {[FORM_ERROR]: error};
  }
};

export const requestBranchesForFullScreeningRequest = (renterId, isGuarantor) => async dispatch => {
  try {
    dispatch(actions.availableBranchesRequest());
    const branches = isGuarantor
      ? await getAgentBranchesForRequestFullReferencing()
      : await getBranchConnections(renterId);

    dispatch(actions.availableBranchesSuccess(branches));
  } catch (e) {
    dispatch(actions.availableBranchesFailure(extractError(e)));
  }
};

export const clearAvailableBranches = () => dispatch => {
  dispatch(actions.clearAvailableBranches());
};

export const sendFullScreeningRequest = ({
  branchId,
  branchConnectionId,
  guaranteedRenterId,
  hasConnection,
  isGuarantor,
  renterId,
}) => async dispatch => {
  try {
    if (branchConnectionId) {
      await requestFullScreeningBranchConnection(branchConnectionId);

      return null;
    }
    if (!hasConnection) {
      // TODO: Remove this when old screening is disabled
      await requestFullScreeningFromGuarantorNoConnection({
        branchId,
        guarantorId: renterId,
        renterId: guaranteedRenterId,
      });
    } else {
      await requestFullScreening({
        branchId,
        renterId,
        isGuarantor: !!isGuarantor,
      });
    }

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.availableBranchesFailure(error));

    return {[FORM_ERROR]: error};
  }
};

export const getIsIdentityVerificationEnable = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getIsIdentityVerificationEnableRequest());

    const agencyId = getState().auth.profile.agency.id;

    const isIdVerificationEnabled = await remoteConfig.getBoolean(IS_IDENTITY_VERIFICATION_ENABLED);
    const whitelistedIDVAgencies = await remoteConfig.getString(WHITELISTED_IDV_AGENCIES);

    dispatch(
      actions.getIsIdentityVerificationEnableSuccess(
        isIdVerificationEnabled || whitelistedIDVAgencies.includes(agencyId),
      ),
    );
  } catch (error) {
    dispatch(actions.getIsIdentityVerificationEnableFailure(extractError(error)));
  }
};

export default reducer;
